@import url("../colors.css");

.top-search {
  width: 60%;
}

.pull-right {
  float: right;
}

@media screen and (max-width: 500px) {
  .top-search {
    width: 100%;
  }
}

.searchOptionText {
  color: var(--dark-rab-blue);
}

.viewAllOption {
  color: white;
  font-size: 13px;
  background-color: var(--dark-rab-blue);
  padding: 5px 10px;
  cursor: pointer;
}

.itemOption {
  color: var(--dark-rab-blue);
  font-size: 13px;
  padding: 10px 10px;

  border-top: 1px solid lightgray;
  cursor: pointer;
}

.itemOption:hover {
  background-color: lightgray;
}

.searchDropdown {
  background-color: darkgray;
  color: var(--light-rab-blue);
  text-align: center;
}

/* slider layout */

.sltabs-search {
  display: flex;
  position: relative;
  background-color: var(--light-rab-blue);
  width: 100% !important;

  justify-content: space-between;
}
.sltabs-search * {
  z-index: 2;
}
.sltabs-search input[type="radio"] {
  display: none;
}

.sltab-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 200px;
  font-size: 14px;
  font-weight: 500;

  margin-top: 5px;
  color: white;
}

.sltab-search {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 200px;
  font-size: 14px;
  font-weight: 500;

  margin-top: 5px;
  cursor: pointer;
  color: white;
}
.sltabs-search input[type="radio"]:checked + label {
  color: var(--light-rab-blue);
}
.sltabs-search input[id="radio-product"]:checked ~ .glider-search {
  transform: translateX(100%);
}
.sltabs-search input[id="radio-stockCode"]:checked ~ .glider-search {
  transform: translateX(200%);
}
.sltabs-search input[id="radio-description"]:checked ~ .glider-search {
  transform: translateX(300%);
}
.glider-search {
  position: absolute;
  display: flex;
  height: 35px;
  width: calc(100% / 4);
  background-color: white;
  z-index: 1;

  border: 1px solid lightgray;

  transition: 0.2s ease-out;
}
