@import url("../colors.css");

#hide-product-button,
#export-button,
#modify-filters-button,
#pin-button {
  margin: 10px;
}
#button-panel {
  float: right;
}
.blue-header {
  color: #fff;
  background-color: var(--dark-rab-blue);
  font-size: 14px;
  height: auto;
  padding: 10px;
  font-weight: bold;
}
.header-product-image {
  max-height: 130px;
  padding-bottom: 5px;
}
.product-name-header {
  font-weight: bold;
  display: inline;
  text-align: center;
  text-overflow: clip;
}
.noProductsPopup-modal {
  width: 500px;
  min-width: 350px;
}
.hide-product-button {
  height: 20px;
  width: 20px;
  padding: 0%;
  background-color: var(--outline-border-gray);
  float: right;
  margin-right: 0px;
  margin-top: 0px;
  border-color: var(--outline-border-gray);
}
.hide-product-button:focus {
  background-color: var(--outline-border-gray);
  border-color: var(--outline-border-gray);
  outline: 0;
}

.minus-icon {
  color: white;
}
.feature-header {
  font-weight: bold;
  background-color: white;
}
.feature-value {
  text-align: center;
}
.table-legends ul {
  list-style-type: none;
}
.table-legends li {
  display: inline;
}
.table-legends {
  padding: 5px;
  margin-bottom: 10px;
  background-color: var(--disclaimer-bg-gray);
}
.certificate-sticker {
  max-width: 40px;
  display: inline;
  margin: 5px;
}
.list-pager {
  margin-top: 100px;
  border: 0px;
}
.next-button {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--dark-rab-blue);
}
.prev-button {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid var(--dark-rab-blue);
}
.pin-icon {
  font-weight: bolder;
}
.name-input-container {
  align-content: left;
  padding: 0px;
}
.normal-button {
  float: right;
  margin: 5px;
}
.table-container {
  width: 100%;
  padding: 10px;
}
.navigation-cell {
  align-content: center;
  padding: 10px;
}

.compare-result-table {
  table-layout: fixed;
}
@media screen and (max-width: 500px) {
  .header-product-image {
    height: 130px;
    padding-bottom: 5px;
  }
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  padding: 4%;
}
#overlay-content {
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.text {
  max-width: 600px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px !important;
  background-color: rgba(0, 0, 0, 0.644);
}
.closeButtonDesign {
  background-color: transparent;
  border-width: 0px;
  font-size: 30px;
  font-weight: 900;
  float: right;
  color: var(--light-rab-blue);
}
.tutorial-prev-button {
  position: fixed;
  top: 50%;
  left: 5%;
  display: inline-block;
}
.tutorial-next-button {
  position: fixed;
  top: 50%;
  right: 5%;
  display: inline-block;
}
.tutorial-complete-button {
  margin: 10px 0px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}
.tutorial-prev-button,
.tutorial-next-button,
.tutorial-complete-button {
  border-radius: 5px !important;
  font-size: 20px;
  font-weight: 500;
}
.hide-component {
  display: none;
}

@media screen and (max-width: 500px) {
  .text {
    max-width: 400px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    text-align: center;
  }
  .tutorial-prev-button,
  .tutorial-next-button,
  .tutorial-complete-button {
    border-radius: 5px !important;
    font-size: 14px;
    font-weight: 400;
  }
}

.raise-image {
  transition: all 500ms ease;
}
.raise-image:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
  cursor: pointer;
}

.compare-table-body {
  border: 1px solid var(--horizontal-line-gray);
}
