@import url("../colors.css");

.prev-button {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;

  border-right: 20px solid var(--dark-rab-blue);
}
.next-button {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;

  border-left: 20px solid var(--dark-rab-blue);
}

.btn-custom {
  padding: 0px;
  border: none;
}

.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
  background-color: transparent;
  border-color: transparent;
}
.line-separator {
  border-color: darkgray;
  padding: 0%;
}
b {
  text-decoration-line: underline;
}

.radio-element {
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  margin-top: 1.5rem;
  justify-content: center;
  align-items: center;
}

.footer-buttons {
  display: flex;
}

.filter-container {
  display: flex;
  justify-content: center;
}
