@import url("../colors.css");

.disclaimer-quote {
  background-color: lightgray;
  padding: 1%;
  width: 100%;
}
.Error-message {
  color: red;
  font-weight: bold;
}
.estimate-summary-header {
  font-size: 16px;
  margin-top: 20px;
  padding: 5px;
  background-color: var(--dark-rab-blue);
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
}
.disabled-input {
  background-color: lightgray;
}
.estimate-summary-table-cell {
  min-height: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.estimate-summary-each-row {
  font-size: 16px;
  padding: 2px;
  border-left: 0.5px solid lightgray;
  border-right: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
  display: flex;
  align-items: center;
}
.text-bold {
  font-weight: bold;
}
.pin-icon {
  font-weight: bolder;
}
.bottom-column {
  vertical-align: bottom;
  float: right;
  margin-bottom: 10px;
  margin-top: 0px;
  height: auto;
}

.clear-all-button {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background-image: none;
  border: 1px solid, #a6a7a7;
  margin-top: 20px;
  margin-right: 15px;
  float: right;
}
.clear-all-button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.096),
    0 17px 50px 0 rgba(0, 0, 0, 0.048);
  background: white;
  border-color: var(--light-rab-blue);
  color: 074391;
}
.clear-all-button:focus {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
}
.button-blue-summary {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  margin-left: 5px;
  margin-top: 22px;
}
.attachment-name {
  overflow: hidden;
  text-overflow: clip;
  word-wrap: unset;
  white-space: nowrap;
}
.attachment-container {
  margin: 5px;
  margin-top: 0px;
  padding: 5px;
  min-width: 30px;
  max-width: 60px;
  min-height: 80px;
  border: 1px solid lightgray;
  margin-left: auto;
  margin-right: auto;
}
.dropdown-item {
  cursor: pointer;
  padding: 4%;
}
.dropdown-item:hover {
  background-color: var(--horizontal-line-gray);
}
.popup-modal > .modal-dialog {
  width: 50%;
  min-width: 300px;
}
.button-blue-modified {
  min-width: 250px;
  min-height: 40px;
  margin-bottom: 20px;
  margin-top: 50px;
  padding: 10px;
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
}
.button-blue-modified:hover {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
}
.align-bottom {
  margin-top: 50%;
}

.order-summary {
  width: 100%;
  border: 1px solid black;
  text-align: center;
  align-content: center;
  justify-content: center;
}

table#order-summary th {
  background-color: var(--dark-rab-blue);
  color: white;
  text-align: center;
  height: 35px;
}
table#order-summary td {
  height: 40px;
}

.fit-image {
  max-width: 100%;
  max-height: 100%;
}
.scrollable-element {
  scrollbar-width: 19px !important;
}

.estimate-page-container {
  margin-top: 50px;
  margin-bottom: 50px;
}

.set-quantity {
  width: 100px;
  min-height: 20px;
  padding: 6px 12px;
}
.shipping-details-table {
  border-top: 3px solid var(--outline-border-gray);
  border-bottom: 3px solid var(--outline-border-gray);
  margin: 16px;
  height: 550px;
  display: flex;
}
.shipping-details-table-pinned {
  border-top: 3px solid var(--outline-border-gray);
  border-bottom: 3px solid var(--outline-border-gray);
  margin: 16px;
  display: flex;
}
.shipping-details-heading {
  font-size: 16px;
  color: var(--light-rab-blue);
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bolder;
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
}
.button-align {
  justify-content: end;
  display: block;
}
#bottom {
  position: relative;
  margin-top: 60%;
}
.col-border {
  border-right: 3px dotted var(--outline-border-gray);
}

.valid-input {
  border-radius: 2px solid;
  border-color: green;
}

.invalid-input {
  border-radius: 2px solid;
  border-color: red;
}

.order-details-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttons-row {
  align-items: flex-start;
  padding-bottom: 10px;
}

.button-del-blue {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  width: 150px;
}

.button-del-blue:hover {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
  font-weight: bold;
  width: 150px;
}

.popup-row {
  display: flex;
  align-items: center;
}

.toggle-popup-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.popup-labels {
  font-size: 14px;
  text-align: right;
  width: 100%;
  margin-bottom: 15px;
}

.popup-width {
  width: 500px !important;
}

.clear-filter-button {
  border: 0;
  color: var(--light-rab-blue);
}

.clear-filter-button:hover {
  border: 0;
  color: var(--light-rab-blue);
  background: white;
}

.active-button {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
  font-weight: bold;
}

[type="date"] {
  background: white
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    98% 50% no-repeat;
  padding-left: 12px;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 25px;
}

.subtotal-text {
  color: black;
  text-shadow: 1px 0 black;
  letter-spacing: 1px;
  font-size: 22px;
  font-weight: bold;
}

.empty-cart {
  margin: 16px;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 15px;
}

.cart-loading-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
