@import url("../colors.css");

.branch-navigation-container {
  margin: 10px 10px 50px 10px;
}
.add-user-modal > .modal-dialog {
  width: 50%;
  min-width: 250px;
}

.icons {
  height: 2.5rem;
  width: 2.5rem;
}

.top-search {
  width: 600px;
}

.pull-right {
  float: right;
}

.export-dist-button {
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 6px 30px !important;
}

.export-dist-button:hover {
  color: #fff !important;
  background: var(--light-rab-blue) !important;
}

.add-user-button {
  font-weight: bold;
  padding: 6px 30px !important;
}

.back-button-branch {
  padding: 25px 0px 0px 0px;
  width: fit-content;
}

.searchbox,
.add-export {
  padding: 0px !important;
}

.no-padding-branch {
  padding: 0px !important;
  text-align: right;
}

.big-blue-text-branch {
  font-size: 35px;
  font-weight: bolder;
  color: var(--light-rab-blue);
  vertical-align: middle;
  word-break: break-all;
  overflow-wrap: break-word;
}

.blue-text {
  border: transparent;
  background-color: transparent;
  color: var(--entity-text-blue);
  font-weight: bolder;
}

.green-circle {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  background: green;
}

.gray-circle {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  background: gray;
}

.prev-button {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;

  border-right: 20px solid var(--dark-rab-blue);
}

.btn-custom {
  padding: 0px;
  border: none;
}

.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
  background-color: transparent;
  border-color: transparent;
}

.backToButton {
  float: left;
  width: 200px;
  font-size: 16px;
  color: var(--light-rab-blue);
  cursor: pointer;
}
