@import url("../colors.css");

.estimate-history-header {
  font-size: 16px;
  margin-top: 20px;
  background-color: var(--dark-rab-blue);
  color: white;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
}
.estimate-history-row {
  border-bottom: 0.5px solid gray;
  background-color: lightgray;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
}
.estimate-history :hover {
  cursor: pointer;
}
.estimate-history {
  font-size: 13px;
  border-left: 0.5px solid gray;
  border-right: 0.5px solid gray;
  border-bottom: 0.5px solid gray;
}
.estimate-detail-row {
  font-size: 13px;
}
.estimate-page-container {
  margin-top: 50px;
  margin-bottom: 50px;
}
table#estimate-detail {
  width: 100%;
  height: fit-content;
  margin-block: auto;
}
table#estimate-detail td {
  padding: 10px;
  border: 1px solid lightgray;
  cursor: default !important;
  vertical-align: middle;
}
table#estimate-detail th {
  padding: 10px;
  border: 1px solid lightgray;
  cursor: default !important;
}
.date-filter-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.button-blue-summary {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  margin-left: 5px;
  margin-top: 22px;
}
.button-blue-summary:hover {
  color: var(--light-rab-blue);
  font-weight: bold;

  background: white;
  border-color: var(--light-rab-blue);
  margin-left: 5px;
  margin-top: 22px;
}

.center-in-td {
  margin: auto;
  text-align: center;
  font-size: 25px;
}

[type="date"] {
  background: white
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    98% 50% no-repeat;
  padding-left: 12px;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 25px;
}
