@import url("../colors.css");

.cta-gray {
  color: var(--link-blue);
  background: #d1d3d4;
  text-align: left;
}
.cta-msg {
  padding: 10px 0px;
}
.cat-container {
  padding: 0px;
}
#header .box {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0;
}
#header .header-msg {
  max-height: 36px;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 24px;
}
.col-lg-6 {
  width: 50%;
}

a:hover {
  color: var(--link-blue);
}
a:hover,
a:focus {
  color: var(--light-rab-blue);
  text-decoration: underline;
}
a:active,
a:hover {
  outline: 0;
}
