@import url("../colors.css");

.search-result-container {
  margin: 10px 10px 50px 10px;
}

.search-result-row-cell {
  min-height: 50px;
  text-align: center;

  border-bottom: 0.05px solid lightgray;
  border-top: 0.05px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-result-row-cell:first-child {
  border-left: 0.05px solid lightgray;
}

.search-result-row-cell:last-child {
  border-right: 0.05px solid lightgray;
}

.search-result-row-cell#search-specs > div > p {
  text-align: center;
}

.search-result-row-headcell {
  min-height: 50px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
}

.search-result-row-header {
  font-size: 16px;
  margin-top: 10px;
  background-color: var(--dark-rab-blue);
  color: white;
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
}

.search-result-popup {
  font-size: 16px;
  background-color: white;
  display: flex;
}

.scrollable-div-search-result::-webkit-scrollbar {
  width: 8px;
  background-color: lightgray;
}

.scrollable-div-search-result::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
.sort-icon-default {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  color: white;
  padding-left: 8px;
  font-size: 12px;
}

.sort-icon-settings {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px 5px;
}

.copy-or-add {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
