:root {
  /* Default RAB Colors */
  --dark-rab-blue: #1b2d5b;
  --light-rab-blue: #074391;

  /* Your Pricing Color */
  --rab-golden: #e2bb60;

  /* Accessory Colors */
  --accessory-bronze: #543521;
  --accessory-black: #231f20;
  --accessory-dove-gray: #97a6a7;
  --accessory-blue: #174792;
  --accessory-red: #c52026;
  --accessory-yellow: #fadf07;
  --accessory-green: #167257;
  --accessory-garden-green: #104a38;
  --accessory-hammertone: #808285;
  --accessory-sparkle-silver: #d1d2d4;
  --accessory-border: #cecfd1;

  /* Other Colors */
  --link-blue: #00285d;
  --popup-red: #bb2924;
  --coffee-text: #444444;
  --horizontal-line-gray: #dddddd;
  --outline-border-gray: #adadad;
  --privacy-green: #66bb6a;
  --slider-bg-gray: #e6eef9;
  --entity-text-blue: #0a74da;
  --home-icon-red: #ff7979;
  --disclaimer-bg-gray: #dad9d9;
  --dropdown-highlight: #f5f5f5;
}
