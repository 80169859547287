#video-display {
    clear: both;
    height: auto;
    background-color: rgb(121, 121, 121);
}

#video-display iframe {
    width: 100%;
    height: stretch;
    border: none
}

#video-display object {
    width: 100%;
    height: 100%;
}

#video-display embed {
    width: 100%;
    height: 100%;
}
.video-player-modal>.modal-dialog>.modal-content>.modal-body{
    height: stretch;
}
.video-player-modal>.modal-dialog>.modal-content{
    margin-bottom: 15%;
}