@import url("../colors.css");

.login-container {
  margin-top: 5%;
}

.links {
  text-align: center;
}

.heading {
  font-weight: bold;
  color: var(--light-rab-blue);
  text-align: center;
}

.center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
