.container-div {
  margin: 50px 0;
}
.inst-btn {
  margin: 10px;
}
.text-message {
  text-align: center;
}
.attachment-name {
  overflow-wrap: break-word;
}

.hide-google-map {
  position: relative !important;
  width: 100% !important;
  height: 500px !important;
  display: none;
}
.show-google-map {
  position: relative !important;
  width: 100% !important;
  height: 500px !important;
}
.gallery-image {
  float: left !important;
  width: 200 !important;
  height: 175 !important;
}
.download-checkbox {
  display: inline-block;
  vertical-align: middle;
}
.download-btn {
  max-width: 85% !important;
  text-align: center;
}
.image-container {
  vertical-align: middle;
}

.gallery-image-box {
  height: 150px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
}
.galleryGrid-image {
  max-width: 125px;
  max-height: 125px;
  margin: auto;
}
.pac-container {
  z-index: 10000 !important;
  display: block;
  width: 85%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.autocompleteMain-container {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.ReactModal__Overlay {
  top: 4% !important;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  box-shadow: 5px 5px 3px #aaaaaa;
}
.react-contextmenu :hover {
  background-color: #e6e6e6;
}

#galleryFile {
  display: none;
}
#chooseFile {
  align-items: flex-start;
  text-align: center;
  cursor: default;
  color: buttontext;
  background-color: buttonface;
  box-sizing: border-box;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonface;
  border-image: initial;
}
#classEdit {
  border: 1px solid rgb(38, 165, 216);
}

.hideButton {
  display: none;
}

.normal-product-details-container {
  margin: 10px;
  padding: 5px;
  min-width: 190px;
  max-width: 220px;
  min-height: 300px;
  border: 1px solid rgb(199, 196, 196);
  margin-left: auto;
  margin-right: auto;
}

.month-product-details-container {
  margin: 10px;
  padding: 5px;
  min-width: 190px;
  max-width: 220px;
  min-height: 300px;
  border: 3px solid #d28d29;
  margin-left: auto;
  margin-right: auto;
}

.button-panel {
  float: right;
}

.disabledMenu {
  display: none;
}

input[type="radio"] {
  vertical-align: middle;
  margin-top: -2px;
}

.sortby {
  padding-left: 0%;
}

.organizeby {
  padding-right: 0%;
  text-align: right;
}

.grayed-line {
  border-color: darkgray;
  padding: 0%;
  margin: 0px;
}
