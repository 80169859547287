.distributor-container {
  margin: 10px 10px 50px 10px;
}

.add-user-modal > .modal-dialog {
  width: 50%;
  min-width: 250px;
}

.icons {
  height: 2.5rem;
  width: 2.5rem;
}

.noOption {
  text-align: center;
  display: block;
  size: 12px;
}

.top-search {
  width: 600px;
}

.pull-right {
  float: right;
}
.deletepopup-modal {
  width: 500px;
  min-width: 250px;
}
.export-dist-button {
  color: black;
  background-color: white;
  border: 1px solid black;
  padding: 6px 30px !important;
}

.export-dist-button:hover {
  color: #fff !important;
  background: var(--light-rab-blue) !important;
}

.add-user-button {
  font-weight: bold;
  padding: 6px 30px !important;
}

.searchbox,
.add-export {
  padding: 0px !important;
}

.no-padding-dist {
  padding: 0px !important;
  text-align: right;
}

.big-blue-text {
  font-size: 50px;
  font-weight: bolder;
  color: var(--dark-rab-blue);
  text-shadow: 0px 1px, 1px 0px, 1px 1px;
  word-break: break-all;
  overflow-wrap: break-word;
}

.blue-text {
  border: transparent;
  background-color: transparent;
  color: var(--entity-text-blue);
  font-weight: bolder;
}

.green-circle {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  background: green;
}

.gray-circle {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50% !important;
  color: #fff;
  font-weight: bolder;
  text-align: center;
  background: gray;
}

.inputText {
  color: black;
}

.valid-input {
  border-radius: 2px solid;
  border-color: green;
}

.invalid-input {
  border-radius: 2px solid;
  border-color: red;
}

/* Switch CSS */
.switch-dist {
  position: relative;
  display: inline-block;
  width: 3.75em;
  height: 2.125em;
}

.switch-dist input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-dist {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-dist:before {
  position: absolute;
  content: "";
  height: 1.625em;
  width: 1.625em;
  left: 0.25em;
  bottom: 0.25em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-dist {
  background-color: var(--light-rab-blue);
}

input:focus + .slider-dist {
  box-shadow: 0 0 1px var(--light-rab-blue);
}

input:checked + .slider-dist:before {
  -webkit-transform: translateX(1.625em);
  -ms-transform: translateX(1.625em);
  transform: translateX(1.625em);
}

/* Rounded sliders */
.slider-dist.round {
  border-radius: 2.125em !important;
}

.slider-dist.round:before {
  border-radius: 50% !important;
}

.home-icons {
  color: var(--home-icon-red);
  font-size: 14px;
  text-shadow: 1px 1px 1px 1px black;
  padding: 0px 3px;
}

.admin-icons {
  color: var(--light-rab-blue);
  font-size: 14px;
  text-shadow: 1px 1px 1px 1px black;
  padding: 0px 3px;
}

.star-icons {
  font-size: 14px;
  text-shadow: 1px 1px 1px 1px black;
  padding: 0px 3px;
}

.flex-component {
  display: flex;
}
