.distributor-page-container {
  margin: 50px 10px;
}

.line-separator {
  border-color: darkgray;
  padding: 0%;
}

.branch-options-1 {
  padding-left: 0%;
}

.subtext {
  font-size: small;
}
