@import url("../colors.css");

.content-usage-container {
  margin: 50px 10px;
}
.blue-header {
  color: #fff;
  background-color: var(--dark-rab-blue);
  font-size: 14px;
  height: auto;
  padding: 10px;
  font-weight: bold;
}
.range-selection-container {
  width: 80%;
  align-self: center;
  margin-left: auto;
  margin-right: 0;
}
.name-column {
  text-align: center;
}
.count-column {
  text-align: center;
}
.date-selection {
  font-size: 14px;
  min-width: 160px;
}
input:invalid {
  border: 2px solid var(--popup-red);
}
input:invalid + span:after {
  content: "✖";
  padding-left: 5px;
  color: var(--popup-red);
  font-weight: bold;
  font-size: 14px;
}

[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    98% 50% no-repeat;
  padding-left: 12px;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  width: 25px;
}
