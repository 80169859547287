

.app-container {
  min-height: 100%;
  width: 100%;
  position: absolute;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  min-width: 1400px;
}

.app-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.custom-container {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.confirm-reset {
  color: white;
  text-decoration: none !important;
}
.confirm-reset:hover {
  color: white;
  text-decoration: none !important;
}
.confirm-reset:focus {
  color: white;
  text-decoration: none !important;
}
