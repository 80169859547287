/* Mac UI */

@media (min-width: 768px) and (max-width: 1500px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
    margin-left: 1.5%;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    position: absolute;
    background-color: var(--dark-rab-blue);
    z-index: 3;
    max-height: none !important;
    width: 50%;
    right: 0;
    padding: 0;
  }
  .navbar-fixed-top {
    top: 0;
    position: -webkit-sticky; /* Safari */
    position: fixed;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
  }
  .navbar-collapse .navbar-nav > li {
    float: none !important;
  }
  .collapse.in {
    display: block !important;
  }

  .log-out {
    border-top: white 1px solid;
  }

  .navbar-right {
    margin-right: 0px;
  }

  .navbar-default .navbar-collapse {
    border-color: transparent;
  }

  .navbar-nav {
    overflow-y: auto !important;
    max-height: 300px;
    overflow-x: hidden;
  }

  .navbar-nav::-webkit-scrollbar {
    width: 4px;
    background-color: var(--dark-rab-blue);
  }

  .navbar-nav::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  .dropdown-menu {
    border-radius: 0px;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:focus,
  .dropdown-menu > .active > a:hover {
    background-color: transparent;
    color: black;
    white-space: normal;
    width: 175px;
  }

  .dropdown-submenu > .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    border-radius: 0px;
  }

  .dropdown-submenu > .dropdown-menu::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  .dropdown-submenu > .dropdown-menu::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  .dropdown-menu > li {
    border-bottom: 1px solid lightgray;
  }

  .dropdown-menu > li:last-child {
    border-bottom: 0px;
  }

  .dropdown-menu > li > a {
    white-space: normal;
    width: 170px;
  }
}
