/* iPad UI */

@media (max-width: 767px) {
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    position: absolute;
    background-color: var(--dark-rab-blue);
    z-index: 3;
    max-height: none !important;
    width: 100%;
    left: 0;
    margin: 0 !important;
    padding: 0px;
  }

  .navbar-nav {
    float: none !important;
    margin: 0;
  }

  .navbar-nav {
    overflow-y: auto !important;
    max-height: 300px;
    overflow-x: hidden;
  }

  .navbar-nav::-webkit-scrollbar {
    width: 4px;
    background-color: var(--dark-rab-blue);
  }

  .navbar-nav::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
  }

  .log-out {
    border-top: white 1px solid;
  }

  .dropdown-menu {
    border-radius: 0px;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a {
    background-color: #fff;
  }

  .dropdown-menu > li {
    border-bottom: 1px solid lightgray;
  }

  .dropdown-menu > li:last-child {
    border-bottom: 0px;
  }

  .dropdown-menu > li > a {
    white-space: normal;
    width: 170px;
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:focus,
  .dropdown-menu > .active > a:hover {
    background-color: transparent;
    color: black;
    white-space: normal;
    width: 175px;
  }

  .navbar-header .navbar-nav > li {
    float: left !important;
  }
  .navbar-nav .open .dropdown-menu {
    background-color: white;
    position: absolute;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
  @media (max-width: 500px) {
    .dropdown-submenu:hover > .dropdown-menu {
      position: absolute;
    }
  }

  .dropdown-submenu > .dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
    border-radius: 0px;
  }
}
