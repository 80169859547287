.help-note {
  display: block;
  margin: 5px;
  padding: 5px;
  font-size: 14px;
}
.order-list-container {
  padding-top: 0px;
  max-height: 500px;
  overflow: auto;
}
.drag-item {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 5px;
  border-radius: 5px !important;
  border: 2px lightgray solid;
}
.drag-item-title {
  font-weight: bold;
  font-size: 16px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.drag-item-content {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.drag-item-image {
  max-height: 100px;
}
