@import url("../colors.css");

.config-details-td {
  vertical-align: top;
  font-size: 14px;
  word-wrap: break-word;
  min-width: 130px;
  min-height: 50px;
  padding: 10px;
}
.config-details-productmodel-td {
  vertical-align: top;
  font-size: 14px;
  word-wrap: break-word;
  width: 180px !important;
  min-width: 180px !important;
  max-width: 200px !important;
  min-height: 50px;
  padding: 10px;
}

.config-details {
  background-color: var(--dark-rab-blue);
  color: white;
  padding: 5px;
  text-align: center;
  text-transform: capitalize;
  height: 60px;
  word-wrap: break-word;
  border: 1px solid var(--dark-rab-blue);
}

.smaller-input {
  font-size: 16px;
  max-width: 100px;
  text-align: center;
}

.light {
  background-color: white;
  color: black;
  padding: 7px;
  white-space: normal;
  text-align: center;
  border: 1px solid black;
  border-top: 0;
  min-height: 30px;
  word-wrap: break-word;
  min-width: 110px;
  max-width: 130px;
  cursor: pointer;
}
.primary {
  background-color: #e7e5ff;
  color: black;
  padding: 7px;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  min-height: 30px;
  border: 1px solid black;
  border-top: 0;
  min-width: 110px;
  max-width: 130px;
  cursor: pointer;
}
.disabled-cell {
  background-color: lightgray;
  color: black;
  padding: 7px;
  word-wrap: break-word;
  white-space: normal;
  text-align: center;
  min-height: 30px;
  border: 1px solid black;
  border-top: 0;
  min-width: 110px;
  max-width: 130px;
  cursor: not-allowed;
}

.panel-config {
  margin: 20px;
}

.config-table {
  margin: auto;
}

.config-columns {
  display: flex;
  justify-content: center;
}

.margins-disclaimer {
  margin: 40px;
  font-size: 12px;
}

.horizontal-line {
  background-color: var(--horizontal-line-gray);
  height: 1px;
  margin-bottom: 10px;
}

.circle {
  height: 23px;
  width: 23px;
  border-radius: 50% !important;
  display: inline-block;
  cursor: pointer;
}
.circle-selected {
  height: 23px;
  width: 23px;
  border-radius: 50% !important;
  display: inline-block;
  cursor: pointer;
}

.bronze {
  background-color: var(--accessory-bronze);
}
.matte-black {
  background-color: var(--accessory-black);
}
.black-sandtex {
  background-color: var(--accessory-black);
}
.mirror-black {
  background-color: var(--accessory-black);
}
.white {
  background-color: white;
  border: 1px solid var(--accessory-border);
}

.dove-gray {
  background-color: var(--accessory-dove-gray);
}
.blue {
  background-color: var(--accessory-blue);
}
.red {
  background-color: var(--accessory-red);
}
.yellow {
  background-color: var(--accessory-yellow);
}
.green {
  background-color: var(--accessory-green);
}
.garden-green {
  background-color: var(--accessory-garden-green);
}
.hammertone {
  background-color: var(--accessory-hammertone);
}
.sparkle-silver {
  background-color: var(--accessory-sparkle-silver);
}
.gray {
  background-color: gray;
}
.custom {
  content: "\e086  !important";
}
.bronze:hover,
.bronze:focus {
  background-color: var(--accessory-bronze);
  border: 1px solid blue;
}
.matte-black:hover,
.matte-black:focus {
  background-color: var(--accessory-black);

  border: 1px solid blue;
}
.black-sandtex:hover,
.black-sandtex:focus {
  background-color: var(--accessory-black);

  border: 1px solid blue;
}
.mirror-black:hover.mirror-black:focus {
  background-color: var(--accessory-black);
  border: 1px solid blue;
}
.white:hover,
.white:focus {
  background-color: white;
  border: 1px solid var(--accessory-border);

  border: 1px solid blue;
}

.dove-gray:hover,
.dove-gray:focus {
  background-color: var(--accessory-dove-gray);

  border: 1px solid blue;
}
.blue:hover,
.blue:focus {
  background-color: var(--accessory-blue);

  border: 1px solid blue;
}
.red:hover,
.red:focus {
  background-color: var(--accessory-red);

  border: 1px solid blue;
}
.yellow:hover,
.yellow:focus {
  background-color: var(--accessory-yellow);

  border: 1px solid blue;
}
.green:hover,
.green:focus {
  background-color: var(--accessory-green);

  border: 1px solid blue;
}
.garden-green:hover,
.garden-green:focus {
  background-color: var(--accessory-garden-green);

  border: 1px solid blue;
}
.hammertone:hover,
.hammertone:focus {
  background-color: var(--accessory-hammertone);

  border: 1px solid blue;
}
.sparkle-silver:hover,
.sparkle-silver:focus {
  background-color: var(--accessory-sparkle-silver);
  border: 1px solid blue;
}
.gray:hover,
.gray:focus {
  background-color: gray;
  border: 1px solid blue;
}

.container-colorpicker {
  outline: 2px solid var(--accessory-border);
  background: white;
  min-height: 120px;
  padding: 1%;
  margin-bottom: 1px;
  height: auto;
}
.colors {
  padding: 15px 5px 0px 5px;
}

.colors-column {
  padding: 15px 5px 0px 5px;
}

table#configtable {
  border-collapse: separate;
  border-spacing: 10px 0;
  align-items: top;
}
.pc-section {
  padding-right: 12px;
  margin-bottom: 20px;
}
table#configtable th {
  min-width: 110px;
  align-items: top;
}
table#configtable td {
  min-width: 110px;
  align-items: top;
}
.visible-div {
  visibility: visible;
}
.invisible-div {
  visibility: hidden;
}

.triangle {
  width: 30px;
  height: 15px;
  border-bottom: solid 15px var(--accessory-border);
  border-left: solid 15px transparent;
  border-right: solid 15px transparent;
  margin: auto;
}
.name {
  font-size: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.product-details-container-config {
  min-width: 190px;
  max-width: 220px;
  border: 1px solid rgb(199, 196, 196);
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  cursor: pointer;
}
.product-details-container-config-selected {
  min-width: 190px;
  max-width: 220px;
  border: 1px solid rgb(199, 196, 196);
  margin-left: auto;
  margin-right: auto;
  background-color: lightgray;
  cursor: pointer;
}

.product-content-config {
  min-width: 180px;
  max-width: 200px;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 10%;
  right: 10%;
  margin: auto;
}
.quickShips-header {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  background-color: var(--dark-rab-blue);
  color: white;
  width: 100%;
  text-align: center;
}
.product-name-config {
  text-align: center;
}

.section-row-headcell {
  min-height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-row-cell {
  min-height: 50px;

  border-bottom: 0.5px solid lightgray;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-row-cell:first-child {
  border-left: 0.5px solid lightgray;
}

.section-row-cell:last-child {
  border-right: 0.5px solid lightgray;
}

.section-row-header {
  font-size: 16px;
  margin-top: 10px;
  background-color: var(--dark-rab-blue);
  color: white;
  width: 100%;
  text-align: center;
}

.scrollable-div-subtab {
  overflow-y: auto !important;
  max-height: 375px;
  overflow-x: hidden;
  padding-right: 8px;
}

.scrollable-div-subtab::-webkit-scrollbar {
  width: 8px;
  background-color: lightgray;
}

.scrollable-div-subtab::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
.warehouse-fee {
  color: black;
  font-weight: normal;
}

.image-gallery-slide-wrapper {
  z-index: 1 !important;
}

.product-preview-section {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  font-weight: 600;
  gap: 10px;
}

.product-preview-heading {
  margin-bottom: 0px;
  padding-right: 30px;
}

#partCategory {
  width: 200px;
}

#prod-config-EST-tooltip .tooltip-inner {
  max-width: 400px !important;
}

.option-title {
  text-transform: capitalize;
}

.all-constraints-modal {
  overflow-y: initial !important;
}

.all-constraints-modal-body {
  max-height: 60vh;
  overflow-y: auto;
}

.message-content {
  margin-left: 2vh;
  margin-bottom: 0.5vh;
  font-size: 15px;
}

#enabled-constraint-hover {
  background: black;
  color: white;
  padding: 10px 5px;
  width: 150px;
}

#enabled-constraint-hover > .arrow:after {
  border-right-color: black !important;
}
::after .popover.right > .arrow:after {
  border-right-color: black !important;
}
#disabled-constraint-hover .tooltip-inner {
  padding: 0px;
}

#disabled-constraint-hover .tooltip-arrow {
  border-width: 0 !important;
  border-right-color: transparent !important;
}

.constraints {
  margin-left: 35px;
}

#custom-color-selector .popover-title {
  background-color: var(--light-rab-blue);
  color: white;
  text-align: center;
}

hr.custom-color-hr {
  border: 2px solid lightgray;
  border-radius: 2px !important;
}

.square {
  height: 25px;
  width: 25px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 2px;
}
.square-selected {
  height: 25px;
  width: 25px;
  display: inline-block;
  cursor: pointer;
}

.custom-color-div {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  word-wrap: break-word;
}

.accessory-images {
  display: flex;
  align-items: center;
  height: 100px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.accessory-paint-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  font-size: 13px;
}

.no-configurator-section {
  text-align: center;
  font-size: 20px;
}

.no-configurator-section-redirect {
  color: var(--light-rab-blue);
}

.copy-or-add {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.current-configurator {
  min-width: 125px;
  height: auto;
  background: #eeeeee;
  padding-right: 30px;
}

.current-configurator-specs {
  min-width: 125px;
  background: white;
  padding-right: 30px;
}

.current-configurator-quantity {
  min-width: 100px;
  background: white;
  padding-right: 6px;
}

.copy-btn {
  margin-top: 27px;
  top: 0px;
  border: 1px solid var(--outline-border-gray);
  background: white;
  color: black;
}

.config-adder-reset {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
