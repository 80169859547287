.manage-content-container {
  margin: 50px 10px;
}

.content-tabs > ul > li > a {
  font-size: medium;
  font-weight: bold;
}

.line-separator {
  border-color: darkgray;
  padding: 0%;
}
