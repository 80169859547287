@import url("../colors.css");

.tableau-header {
  text-align: left;
  color: var(--dark-rab-blue);
  font-weight: bold;
  margin-bottom: 20px;
}
.dashboard-list {
  font-size: 18px;
  margin: 7px;
  height: 60vh;
  overflow-y: auto;
}
.dashboard-item {
  margin: 0 20px;
  padding: 10px;
  cursor: pointer;
  word-wrap: none;
}
.dashboard-item:hover {
  color: var(--light-rab-blue);
  cursor: pointer;
  word-wrap: none;
}
#tableau-panel .pinned-item-container,
.panel {
  max-width: 100%;
}

.dashboard-view {
  text-align: center;
}
.dashboard-view iframe {
  width: 100%;
  min-height: 85vh;
}
.custom-modal > .modal-dialog {
  width: auto;
  margin: 0.5% !important;
}
