@import url("../colors.css");

.your-pricing-container {
  margin: 10px 10px 50px 10px;
}

.role-subheader {
  font-size: 14px;
}

.special-pricing-row-cell {
  min-height: 50px;
  border: 0.5px solid lightgray;
  border-left: 0px;
  border-right: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.special-pricing-row-cell:first-child {
  border-left: 0.5px solid lightgray;
}

.special-pricing-row-cell:last-child {
  border-right: 0.5px solid lightgray;
}

.special-pricing-row-headcell {
  min-height: 50px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
}

.special-pricing-row-header {
  font-size: 16px;
  margin-top: 10px;
  background-color: var(--dark-rab-blue);
  color: white;
  width: 100%;
  text-align: center;
}

.scrollable-div-golden {
  overflow-y: auto !important;
  height: 600px;
  overflow-x: hidden;
  min-height: 500px;
  padding-right: 8px;
}

.scrollable-div-golden::-webkit-scrollbar {
  width: 8px;
  background-color: lightgray;
}

.scrollable-div-golden::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.scrollable-div-auto {
  overflow-y: auto !important;
  height: min-content;
  overflow-x: hidden;
}
.golden-popup {
  display: flex;
  font-size: 16px;
  background-color: white;
}

.admin-title {
  display: flex;
  align-items: flex-end;
}

.copy-or-add {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
