@import url("../colors.css");

.button-confirm-blue {
  color: #fff;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  width: 150px;
}

.button-confirm-blue:hover {
  color: var(--light-rab-blue);
  background: #fff;
  border-color: var(--light-rab-blue);
  font-weight: bold;
  width: 150px;
}
