@import url("../colors.css");

.div-catalogue {
  width: 100%;
  display: inline-block;
}

.form-download-catalogues {
  font-size: 14px;
}
.product-title {
  font-size: 20px;
  font-weight: bold;
}
.product-short-description,
.product-description {
  font-size: 14px;
}
.product-description {
  padding-left: 15px;
}
.attachment-table {
  width: 100%;
  margin: 30px 0px;
}
.attachment-table-row {
  margin: 0px;
  padding: 0px 5px;
  border: 1px solid var(--horizontal-line-gray);
}
.attachment-name-cell,
.attachment-download-cell {
  padding: 0px 10px;
}
.attachment-download-cell {
  width: 1%;
  white-space: nowrap;
}
.gallery-container {
  margin: 20px;
}
.productdownload-btn {
  width: stretch;
  max-width: 110px;
  white-space: nowrap;
}
.download-btn {
  width: stretch;
  max-width: 110px;
}
#pin-button {
  float: right;
}

.image-gallery-slide img {
  height: 85vh;
  max-height: 85vh !important;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}

.image-gallery-thumbnails img {
  height: 12vh;
  max-height: 12vh !important;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;
}

.image-gallery-thumbnails-wrapper {
  margin-top: 30px;
}

.image-gallery-thumbnail {
  border: 2px solid lightgray !important;
}

.image-gallery-thumbnail.active {
  border: 2px solid var(--light-rab-blue) !important;
}
