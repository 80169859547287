.checkbox-inline{
    padding: 0px;
}
.checkbox-inline input[type="checkbox"] {
    display: none;
} 
.small-hr{
    margin: 0px;
}
.filter-button-icon{
    padding: 5px;
    max-width: 70px;
    min-width: 70px;
}
.selected{
    min-width: 80px;
    border-color: brown;
    border-bottom-width: 2px;
    border-style: solid;
}
.disabled, .not-selected{
    min-width: 80px;
    border-color: transparent;
    border-bottom-width: 2px;
    border-style: solid;
}
.disabled > img {
    opacity: .3;
}

.even-margin {
    margin: 5px;
    text-align: center;
}