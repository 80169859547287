@import url("./colors.css");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-radius: 0;
}

*:focus {
  outline: 0 !important;
}

body {
  color: var(--coffee-text);
  font-size: 12px;
  font-family: Montserrat, sans-serif !important;
  overflow-y: auto;
}

body.modal-open {
  height: 100vh;
  width: 100vw;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

body a {
  outline: medium none !important;
  color: var(--coffee-text);
  text-decoration: none;
}

body a:hover {
  color: var(--link-blue);
  text-decoration: none;
}

body img {
  display: block;
  max-width: 100%;
  height: auto;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.pager li > a {
  border-radius: 0px;
}

.btn {
  border-radius: 0px;
}

.form-control {
  border-radius: 0px;
}

.no-padding {
  padding: 0px;
}

.no-margin {
  margin: 0px;
}

.container {
  width: auto;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

.table > thead > tr > td,
.table > thead > tr > th {
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

.button-blue {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  white-space: normal;
}

.button-blue:hover {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
  font-weight: bold;
  white-space: normal;
}

.hide-component {
  display: none;
}

/* Popup design */

.popup-red-text {
  font-weight: bolder;
  color: var(--popup-red);
}

.popup-white-text {
  font-weight: bolder;
  color: white;
  background-color: var(--light-rab-blue);
  font-size: 18px !important;
  font-family: Montserrat, sans-serif !important;
  overflow-y: auto;
}

.popup-blue-text {
  font-weight: bolder;
  color: var(--light-rab-blue);
}

.popup-black-text {
  color: var(--coffee-text);
  padding: 2px !important;
  font-size: 17px;
  font-family: Montserrat, sans-serif !important;
}

.button-red {
  color: white;
  background: var(--popup-red);
  border-color: var(--popup-red);
  text-align: center;
  width: 100px;
}

.button-red:hover {
  color: var(--popup-red);
  background: white;
  border-color: var(--popup-red);
  font-weight: bold;
  text-align: center;
  width: 100px;
}

.button-white {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
  text-align: center;
  width: 100px;
}

.button-white:hover {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  font-weight: bold;
  text-align: center;
  width: 100px;
}

.button-skyblue {
  color: white;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  text-align: center;
  width: 100px;
}

.button-skyblue:hover {
  color: var(--light-rab-blue);
  background: white;
  border-color: var(--light-rab-blue);
  font-weight: bold;
  text-align: center;
  width: 100px;
}

.gold-pricing {
  color: var(--rab-golden);
  font-weight: bold;
}

.scrollable-div {
  overflow-y: auto !important;
  max-height: 300px;
  overflow-x: hidden;
}

.scrollable-div::-webkit-scrollbar {
  width: 8px;
  background-color: lightgray;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.mimic-link {
  background: none !important;
  background-color: transparent;
  border: none;
  padding: 0 !important;
  font-size: 12px;
  font-weight: bolder;
  color: var(--light-rab-blue);
  cursor: pointer;
}

.mimic-link:hover {
  color: var(--light-rab-blue);
}

.loading-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.selected-category-product-image {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.loading-fade {
  animation: fadeIn 500ms ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.default-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
