@import url("../colors.css");

.next-button {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid var(--dark-rab-blue);
}

.btn-custom {
  padding: 0px;
  border: none;
}
.btn-custom.focus,
.btn-custom:focus,
.btn-custom:hover {
  background-color: transparent;
  border-color: transparent;
}
.modal-footer {
  text-align: center;
}
.wizard-guide-button {
  display: inline;
  background: var(--dark-rab-blue);
  color: white;
  padding: 6px 20px;
}
.gray-text {
  margin: 5px;
  color: darkgray;
}

.extra-padding {
  padding: 5px 20px;
}

.filter-container {
  display: flex;
  justify-content: center;
}
