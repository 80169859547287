.container-div {
  margin: 50px 0;
}
.panel-title > a {
  font-weight: bold;
}
.panel-heading {
  padding: 10px;
}
.panel-heading a:before {
  font-family: "Glyphicons Halflings", sans-serif;
  content: "\e114";
  float: right;
  transition: all 0.5s;
}
.panel-heading a.collapsed:before {
  content: "\e080";
  float: right;
}
.no-padding {
  margin: 0%;
  padding: 0%;
}
.attachment-name {
  overflow-wrap: break-word;
}

.category-tabs {
  margin: 30px;
  height: auto;
}

.resource-docs {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0px;
}
