@import url("../colors.css");

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 5%;
}

.policy-popup-modal {
  top: 1vh;
  width: 70% !important;
  min-width: 70vh !important;
  height: 75% !important;
  min-height: 70vh !important;
}

.privacy-round {
  position: relative;
  width: 15px;
  height: 20px;
  margin-right: 20px;
}

.round-label {
  max-width: none;
}

.privacy-round label {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 50% !important;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.privacy-round label:after {
  border: 2px solid white;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 12px;
}

.privacy-round input[type="checkbox"] {
  visibility: hidden;
}

.privacy-round input[type="checkbox"]:checked + label {
  background-color: var(--privacy-green);
  border-color: var(--privacy-green);
}

.privacy-round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.privacy-next-disabled {
  padding: 8px 40px;
  background-color: #c7d0d8;
  float: right;
  font-weight: bolder;
  pointer-events: none;
}

.privacy-next-enabled {
  padding: 8px 40px;
  color: white;
  background-color: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  float: right;
  font-weight: bolder;
}

.privacy-next-enabled:hover {
  padding: 8px 40px;
  color: var(--light-rab-blue);
  background-color: white;
  border-color: var(--light-rab-blue);
  float: right;
  font-weight: bolder;
}

.line-separator-privacy {
  border-color: lightgray;
  padding: 0%;
}

#pdf-display {
  text-align: center;
  height: 75% !important;
  min-height: 50vh !important;
  background-color: gainsboro;
  width: 100%;
}

.privacy-scrollable-div {
  max-height: 650px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: "2px solid lightgray";
  border-radius: 5px;
}

.privacy-scrollable-div::-webkit-scrollbar {
  width: 8px;
  background-color: lightgray;
}

.privacy-scrollable-div::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

.react-pdf__Page__canvas {
  padding-top: 5px;
  margin: auto;
}

.react-pdf__Page__annotations {
  height: 5px !important;
}
