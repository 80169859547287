@import url("../colors.css");

.product-container {
  vertical-align: middle;
}
.product-details-container {
  margin: 10px;
  padding: 5px;
  min-width: 190px;
  max-width: 220px;
  min-height: 270px;
  border: 1px solid rgb(199, 196, 196);
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.product-box {
  height: 100px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
}
.product-image-box {
  height: 200px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  display: block;
}

.product-image {
  max-width: 150px;
  margin: auto;
}
.product-content {
  min-width: 180px;
  max-width: 200px;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 10%;
  right: 10%;
  margin: auto;
}
.product-name {
  text-align: center;
}

.pagination-container {
  margin: 20px;
  padding: 5px;
}
.search-descriptor {
  margin: 20px;
  font-weight: bold;
  text-align: center;
}
