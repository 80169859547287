@import url("../colors.css");

.navbar {
  border-radius: 0px;
}

.navbar-nav {
  transition: 0.2s ease-out;
}

#navbar_collapse.collapse.in {
  width: 350px;
}

#navbar_collapse.collapsing {
  width: 350px;
  overflow-x: hidden;
}

.dropdown-submenu:hover {
  background-color: var(--dropdown-highlight);
}

.navbar-blue {
  background-color: var(--dark-rab-blue);
  border-color: var(--dark-rab-blue);
  position: sticky;
  position: -webkit-sticky; /*for safari browser */
  top: -1px;
  z-index: 3;
}

.navbar-blue .navbar-nav > li > a {
  color: white !important;
  padding: 15px;
}
.navbar-blue .navbar-nav > .active > a,
.navbar-blue .navbar-nav > .active > a:focus,
.navbar-blue .navbar-nav > .active > a:hover {
  background-color: #466c9f;
  color: #fff;
}
.navbar-blue .navbar-nav > .open > a,
.navbar-blue .navbar-nav > .open > a:focus,
.navbar-blue .navbar-nav > .open > a:hover {
  background-color: transparent;
  color: #fff;
}
.navbar-nav > .open:hover {
  color: #fff;
  background-color: transparent;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
  background-color: transparent;
  color: black;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}

.dropdown-menu > .open > .dropdown-menu {
  display: none;
}
