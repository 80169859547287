.dlc-container {
  margin: 50px 0px 50px 0px;
}

.certification-tabs > ul > li > a {
  font-size: medium;
  font-weight: bold;
}

.dlc-result-container {
  margin: 50px 10px;
}
.heading {
  text-transform: capitalize;
}
.product-short-description {
  font-size: 14px;
}

.product-short-description > iframe {
  width: 100%;
  margin-top: 5px;
}

.quick-tabs a:before {
  font-family: "Glyphicons Halflings", sans-serif;
  content: "" !important;
  float: right;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.quick-search-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.product-summary-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.search-bar-config {
  display: flex;
}

.product-summary-section {
  display: flex;
  gap: 12px;
  justify-content: space-evenly;
  align-items: center;
}

.product-summary-columns {
  min-width: 125px;
  height: auto;
  padding-right: 30px;
}
