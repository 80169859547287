@import url("../colors.css");

.user-management-container {
  margin: 50px 10px;
}
.add-user-modal > .modal-dialog {
  width: 50%;
  min-width: 250px;
}

.icons {
  height: 2.5rem;
  width: 2.5rem;
  margin-left: auto;
  margin-right: auto;
}

.editButton {
  border: transparent;
  background-color: transparent;
}

.deleteButton {
  border: transparent;
  color: red;
  background-color: transparent;
}
.noOption {
  text-align: center;
  display: block;
  size: 12px;
}

.top-search {
  width: 600px;
}

.pull-right {
  float: right;
}

.export-button {
  color: black;
  background-color: white;
  border: 1px solid black;
  margin-left: 20px !important;
  padding: 6px 30px !important;
}

.export-button:hover {
  color: white !important;
  background: var(--light-rab-blue) !important;
}

.add-user-button {
  font-weight: bold;
  padding: 6px 30px !important;
}

.searchbox,
.add-export {
  padding: 0px !important;
}

.no-padding-user {
  padding: 0px !important;
  text-align: right;
}

#multi-admin-tooltip .tooltip-inner {
  background-color: white;
  border: 3px solid #cacaca;
  padding: 10px;
  opacity: 1;
  width: 250px;
  max-width: 250px;
  font-size: 12px;
  color: black;
}
