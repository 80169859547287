@import url("../colors.css");

.tooltip-container {
  background-color: white !important;
  border-radius: 0px !important;
  height: 100%;
}

.tooltip-header {
  color: #fff;
  margin-bottom: 5px;
  font-size: 2vh;
  background-color: var(--light-rab-blue);
  padding: 2px;
}

#spec-tooltip .tooltip-inner {
  padding: 0px;
}

#specPopover .popover-content {
  padding: 0px;
  margin: -1px;
}

#specPopover .popup-white-text {
  font-weight: bolder;
  color: white;
  background-color: var(--light-rab-blue);
  font-size: 18px !important;
  font-family: Montserrat, sans-serif !important;
  overflow-y: auto;
  margin: 0px;
  padding: 10px;

  display: flex;
  align-items: center;
}

.popover.right > .arrow {
  top: 50% !important;
}

.custom-glyphicon {
  color: white; /* Change to your desired icon color */
  background-color: var(--light-rab-blue);
  padding: 5px;
  cursor: pointer;
  border: 0px;
}

.link-text {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin: 5px 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
