@import url("../colors.css");

.sltabs {
  display: flex;
  position: relative;
  background-color: white;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15),
    0 6px 12px 0 rgba(24, 94, 224, 0.15);
  width: 370px !important;
  justify-content: space-between;
}
.sltabs * {
  z-index: 2;
}
.sltabs input[type="radio"] {
  display: none;
}
.sltab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 200px;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 5px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}
.sltabs input[type="radio"]:checked + label {
  color: var(--light-rab-blue);
}
.sltabs input[id="radio-1"]:checked ~ .glider {
  transform: translateX(0);
}
.sltabs input[id="radio-2"]:checked ~ .glider {
  transform: translateX(100%);
}
.glider {
  position: absolute;
  display: flex;
  height: 35px;
  width: 185px;
  background-color: var(--slider-bg-gray);
  z-index: 1;
  transition: 0.25s ease-out;
}
