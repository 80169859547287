@import url("../colors.css");

.manage-home-page-container {
  margin: 50px 10px;
}

.manage-product-config-data-container {
  margin: 50px 10px;
}
.blue-header {
  color: #fff;
  background-color: var(--dark-rab-blue);
  font-size: 14px;
  height: auto;
  padding: 10px;
  font-weight: bold;
}
.range-selection-container {
  width: 80%;
  align-self: center;
  margin-left: auto;
  margin-right: 0;
}
.name-column {
  text-align: center;
}
.count-column {
  text-align: center;
}
.date-selection {
  font-size: 14px;
  min-width: 160px;
}
input:invalid {
  border: 2px solid var(--popup-red);
}
input:invalid + span:after {
  content: "✖";
  padding-left: 5px;
  color: var(--popup-red);
  font-weight: bold;
  font-size: 14px;
}

input[type="date"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}
mark {
  color: var(--popup-red);
  background: none;
}

#back-to-default {
  margin: 0 0 15px 15px;
}
