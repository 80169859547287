.pinned-item-container,
.panel {
  height: 100%;
  min-width: fit-content;
}
.panel-title {
  font-weight: bold;
}
.panel-body {
  padding: 5px;
  height: 100%;
}
.pinned-item-table {
  width: 95%;
  height: 100%;
  margin: 10px;
}
.pinned-item-block {
  margin: 0px;
  padding: 0px 2px;
}
.pinned-item-name-cell,
.pinned-item-delete-cell {
  padding: 5px;
  cursor: pointer;
}
.pinned-item-name-cell {
  width: 85%;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.pinned-item-delete-cell {
  width: 1%;
  white-space: nowrap;
  padding: 4px;
}
.support-document-container {
  margin: 20px 10px;
  vertical-align: middle;
}
.support-document-navigation-cell {
  align-content: center;
  vertical-align: middle;
  padding: 5px;
}
.document-container {
  margin: 2px;
  border-top: 0px !important;
}
.document-button {
  width: 180px;
  height: 200px;
}
.document-name {
  width: 150px;
  overflow-wrap: break-word;
  overflow: hidden;
}
.banner-image-div {
  min-height: 500px;
  background-size: cover;
  background-position: center;
}
.carousel {
  z-index: 0;
}

.carousel-caption {
  background-color: rgba(128, 128, 128, 0.753);
  width: 100%;
  right: 0%;
  left: 0%;
}
.support-document-header {
  margin-top: 20px;
}
.word-wraptext {
  word-wrap: break-word;
  width: 200px;
  white-space: normal;
}
@media screen and (max-width: 500px) {
  .banner-image-div {
    min-height: 250px;
  }
}
@media screen and (min-width: 500px) and (max-width: 650px) {
  .banner-image-div {
    min-height: 300px;
  }
}
@media screen and (min-width: 650px) and (max-width: 750px) {
  .banner-image-div {
    min-height: 350px;
  }
}
@media screen and (min-width: 750px) and (max-width: 950px) {
  .banner-image-div {
    min-height: 400px;
  }
}
@media screen and (min-width: 950px) and (max-width: 1100px) {
  .banner-image-div {
    min-height: 450px;
  }
}

td {
  vertical-align: middle;
}

.home-page-loading-icon {
  padding: 50px;
}
