@import url("../colors.css");

.filter-space {
  margin-top: 10px;
  min-width: 220px !important;
  align-content: center;
  border: darkgray;
  border-style: double;
  background-color: var(--disclaimer-bg-gray);
}

.scrollable-div-category {
  overflow-y: auto !important;
  height: 200px;
  overflow-x: hidden;
}

.scrollable-div-category::-webkit-scrollbar {
  width: 8px;
  background-color: lightgray;
}

.scrollable-div-category::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}
.raise-image {
  transition: all 500ms ease;
}

.raise-image:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
  cursor: pointer;
}

.bold-text {
  font-weight: bold;
}
.border-category {
  border-bottom: 1px solid rgb(174, 174, 177);
  width: 100%;
}
.clearance-popup-header {
  font-size: 16px;
  margin-top: 10px;
  background-color: var(--dark-rab-blue);
  color: white;
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
}

.clearance-popup {
  font-size: 16px;

  border-left: 0.5px solid lightgray;
  border-right: 0.5px solid lightgray;
  border-bottom: 0.5px solid lightgray;
  background-color: white;
}

.clearance-row-cell {
  min-height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearance-modal > .modal-dialog {
  width: 90%;
  min-width: 300px;
}

.link-text {
  color: blue;
  text-decoration: "underline";
  cursor: "pointer";
}

#spec-tooltip .tooltip-inner {
  background-color: transparent;
  padding: 10px;
  opacity: 1;
  width: 350px;
  max-width: 350px;
}

.clearance-category-column {
  padding: 10px;
  min-height: 200px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.copy-or-add {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
