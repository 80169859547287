.tutorial-faq-modal {
  width: 40% !important;
  min-width: 400px;
}

.tutorial-faq-button {
  border-radius: 75px !important;
  padding: 8px 40px;
  font-weight: bolder;
}
