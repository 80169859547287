@import url("../colors.css");

.disclaimer {
  background-color: var(--disclaimer-bg-gray);
  padding: 1%;
  float: right;
  vertical-align: bottom;
  min-width: 100%;
}

.title-disclaimer {
  color: red;
  text-align: left;
}

.estimatedShipping {
  background-color: white;
  color: gray !important;
  font-size: 11px;
}

.orderSummary {
  color: transparent;
}
