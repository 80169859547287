@import url("../colors.css");

.app-logo {
  height: 100px;
  width: 100px;
  margin-left: 0px;
  cursor: pointer;
}

.vertical-line {
  border-left: 2px solid gray;
  height: 45px;
  padding-right: 10px;
  margin-left: 10px;
}

#user-info-tooltip .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #cacaca !important;
}

#user-info-tooltip .tooltip-inner {
  background-color: white;
  border: 3px solid #cacaca;
  padding: 10px;
  opacity: 1;
  width: 350px;
  max-width: 350px;
}

.line-separator {
  border-color: darkgray;
  padding: 0%;
}

.welcome-text {
  font-size: 18px;
  font-weight: bolder;
  color: var(--dark-rab-blue);
  float: right;
  padding-right: 10px;
}

.popup-text {
  font-size: 14px;
  font-weight: bolder;
  color: var(--dark-rab-blue);
  word-break: break-word;
}

.sub-text {
  font-size: 14px;
  color: var(--dark-rab-blue);
  word-break: break-word;
}

.low-text {
  font-size: 10px;
  color: var(--dark-rab-blue);
  word-break: break-word;
}

.logout-modal {
  max-width: 600px;
}

.blurred {
  filter: blur(5px);
  opacity: 40%;
}

.estimate-cart-icon {
  color: var(--dark-rab-blue);
}
