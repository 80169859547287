@import url("../colors.css");

.distributor-container {
  margin: 50px 10px;
}

.branch-overview-container {
  margin: 100px 10px;
}

.userlist-container {
  margin: 50px 10px;
}

.branch-overview-col,
.go-back-col {
  padding: 0px;
}
.branch-heading {
  color: var(--light-rab-blue);
  font-weight: bold;
  margin-left: 16px;
  margin-right: 16px;
  padding: 10px;
  font-size: 48px;
}
.branch-info-container {
  height: 70%;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
}
.location-info-container {
  height: 30%;
  background-color: var(--dark-rab-blue);
  color: #fff;
  margin-bottom: 16px;
  padding: 10px;
}
.container-heading {
  height: 10%;
  background-color: var(--dark-rab-blue);
  color: #fff;
  padding: 10px;
  font-size: 14px;
}
.container-body {
  height: 100px;
  min-height: 92px;
  overflow-wrap: break-word;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px;
  font-size: 14px;
}
.agency-container-body {
  height: 200px;
  overflow-wrap: break-word;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
  font-size: 14px;
}

.branch-info-content {
  align-content: center;
  margin: auto;
  text-align: center;
  font-size: 12px;
}
.logo-image {
  margin: auto;
  height: 150px;
  width: 150px;
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.logo-container {
  border-radius: 60px !important;
  overflow: hidden;
  border: 1px rgb(58, 58, 58) solid;
}
.go-back-col {
  padding-top: 30px;
}

.salesagency-info-col {
  height: 300px;
  margin-left: 30px;
  border: 1px solid black;
}

.go-back-button {
  padding: 5px;
  color: white;
  background-color: var(--light-rab-blue);
  border: 1px var(--light-rab-blue);
  padding-right: 40px !important;
}

.branch-info-col,
.user-info-col {
  float: left;
  height: 30px;
  width: 48%;
}

.branch-info-col {
  margin-left: 2%;
}

.user-info-col {
  margin-right: 2%;
}

.icons {
  height: 2.5rem;
  width: 2.5rem;
}
.branches-back-button {
  color: #fff;
  background-color: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  border: 1px solid var(--light-rab-blue);
  overflow: visible;
  text-transform: none;
  overflow-wrap: break-word;
  word-break: break-all;
  width: 90%;
}
.branches-back-button:hover,
.branches-back-button:focus {
  color: var(--light-rab-blue);
  background: #fff;
  border-color: var(--light-rab-blue);
  border: 1px solid var(--light-rab-blue);
  font-weight: bold;
  overflow: visible;
  text-transform: none;
  overflow-wrap: break-word;
  word-break: break-all;
  width: 90%;
  height: 100%;
}

.users-branch-button {
  width: 90%;
  height: 100%;
  min-height: 36px;
  overflow-wrap: break-word;
  font-size: 14px;
  color: #fff;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
}

.add-users-button {
  width: 100%;
  height: 100%;
  min-height: 36px;
  overflow-wrap: break-word;
  font-size: 14px;
  color: #fff;
  background: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  padding: 0px;
}

.back-arrow-col {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}

.branches-text-col {
  font-weight: bolder;
  color: white;
  padding: 0px;
  height: 100%;
  width: 100%;
}
.branches-text-col:hover {
  color: var(--light-rab-blue);
  background: #fff;
  border-color: var(--light-rab-blue);
  font-weight: bold;
}

.active-button {
  color: var(--light-rab-blue);
  background: #fff;
  border-color: var(--light-rab-blue);
  font-weight: bold;
}

.not-assigned-text {
  font-style: normal;
  color: red;
  font-weight: lighter;
}

.branch-overview-row {
  display: flex;
  margin-top: 24px;
  flex-direction: row;
}

.branchAdmin-CSR-row {
  display: flex;
  align-items: flex-start;
  margin-left: 16px;
  padding: 0px;
}

.inner-div {
  white-space: normal;
  display: block;
  height: auto;
}

.change-admin-popup {
  width: 400px !important;
}

.change-radio {
  font-size: 15px;
}

.add-new-users {
  width: 200px;
  font-size: 16px;
  color: var(--light-rab-blue);
  cursor: pointer;

  display: flex;
  align-items: center;
}
