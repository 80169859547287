@import url("../colors.css");

.manage-order-container {
  margin: 50px;
}
.order-track-form {
  font-size: medium;
  font-weight: bold;
}
.manage-orders-container {
  margin-top: 50px;
  margin-left: 50px;
}

.react-bootstrap-table-pagination-total {
  padding-left: 10px;
}
.react-bootstrap-table-page-btns-ul {
  text-emphasis-color: red;
}

.pagination.react-bootstrap-table-page-btns-ul {
  margin: 0px;
  float: right;
}
.react-bootstrap-table-pagination-list.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
  margin-top: 2px;
}
.name-column {
  text-align: center;
}
.inline-package-link {
  color: var(--light-rab-blue);
  font-family: inherit;
  float: left;
  font-weight: bold;
  line-height: 1.1;
  font-size: 14px;
  min-width: 95px !important;
}
.inline-package-link:hover {
  cursor: pointer;
}
input:invalid {
  border: 2px solid var(--popup-red);
}
.order-tracking-table {
  background-color: rgb(214, 214, 214);
  padding: 10px;
  font-weight: bold;
}
.order-tracking-table:hover {
  cursor: pointer;
}

.table#shipment {
  border-collapse: collapse;
}
.table#shipment td {
  height: 50px;
}

.react-bootstrap-table > table > thead > tr > th {
  background-color: var(--dark-rab-blue);
  color: white;
  border: transparent;
  vertical-align: middle;
  min-width: 60px;
}
.react-bootstrap-table > table > thead > tr {
  border: 1px solid black;
}
.react-bootstrap-table > table > tbody > tr > td {
  border: 0px solid black;
  max-width: 150px;
}
.react-bootstrap-table > table > tbody > tr {
  border: 1px solid black;
}
.react-bootstrap-table > table > tbody > tr.expanding-row > td {
  padding: 0px;
  cursor: context-menu;
}
.inline {
  float: left;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  font-size: 14px;
}
.courier {
  min-width: 173px !important;
  padding-left: 20px;
}
.inline-right {
  float: right;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  font-size: 14px;
}
.react-bootstrap-table > table > thead > tr > th > span.order {
  width: 20px !important;
  display: inline-block;
}
.order-track-expand-row {
  background-color: #fff;
  margin: -8;
  border: 0;
  padding-top: 20px;
  padding-bottom: 0;
}
.expand-row-body > td {
  padding: 0;
}
.track-button {
  color: white;
  background-color: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  display: inline-block;
  margin-bottom: 0;
  margin-top: -8px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid;
  overflow: visible;
  text-transform: none;
  border-radius: 12px;
}
.track-button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.096),
    0 17px 50px 0 rgba(0, 0, 0, 0.048);
  background-color: white;
  color: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  font-weight: bold;
}
.track-button:focus {
  background-color: white;
  color: var(--light-rab-blue);
  border-color: var(--light-rab-blue);
  font-weight: bold;
}
.inline-currency {
  float: right;
  font-family: inherit;
  line-height: 1.1;
  font-size: 14px;
  padding-inline-end: 20px;
  padding-inline-start: 20px;
  font-weight: bold;
}

.itemsintable {
  min-width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 0.5fr 2fr 2fr 2fr 0.05fr 1fr;
}
.tableheaders {
  min-width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
}
.searchtextbox {
  border-top-style: hidden;
  border-left-style: hidden;
  border-right-style: hidden;
  outline-color: white;
}
